<template>
    <div class="profile_page">
        <!-- <head-top go-back="true" :head-title="profiletitle"></head-top> -->
        <section>
            <div class="profile-avatar">
                <router-link :to="'/profile/info'" class="profile-link">
                    <img
                        :src="userInfo.avatar"
                        class="privateImage"
                        v-if="userInfo && userInfo.openId"
                    />
                    <div class="profile-title">{{ userInfo.username }}</div>
                </router-link>
            </div>
            <div class="profile-content">
                <section class="info-data">
                    <ul class="clear">
                        <router-link
                            to="/profileRoute"
                            tag="li"
                            class="info-data-link"
                        >
                            <div>
                                <van-icon
                                    name="map-marked"
                                    size="26"
                                    color="#B72E32"
                                />
                                <span class="info-data-bottom">我的路线</span>
                            </div>
                        </router-link>
                        <router-link
                            to="/profileActivity"
                            tag="li"
                            class="info-data-link"
                        >
                            <div>
                                <van-icon
                                    name="star"
                                    size="26"
                                    color="#B72E32"
                                />
                                <span class="info-data-bottom">我的收藏</span>
                            </div>
                        </router-link>
                        <router-link
                            to="/integral"
                            tag="li"
                            class="info-data-link"
                        >
                            <div>
                                <van-icon
                                    name="bill"
                                    size="26"
                                    color="#B72E32"
                                />
                                <span class="info-data-bottom">我的积分</span>
                            </div>
                        </router-link>
                    </ul>
                </section>
                <section class="profile-1reTe">
                    <!-- 我的订单 -->
                    <router-link to="/profile/info" class="myorder">
                        <aside>
                            <van-icon
                                name="setting-o"
                                size="22"
                                color="#231F20"
                            />
                        </aside>
                        <div class="myorder-div">
                            <span>设置</span>
                            <span class="myorder-divsvg">
                                <svg fill="#999">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="#arrow-right"
                                    ></use>
                                </svg>
                            </span>
                        </div>
                    </router-link>
                    <!-- 积分商城 -->
                    <router-link to="/profileComment" class="myorder">
                        <aside>
                            <van-icon
                                name="comment-o"
                                size="22"
                                color="#231F20"
                            />
                        </aside>
                        <div class="myorder-div">
                            <span>我的评论</span>
                            <span class="myorder-divsvg">
                                <svg fill="#999">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="#arrow-right"
                                    ></use>
                                </svg>
                            </span>
                        </div>
                    </router-link>
                    <!-- 饿了么会员卡 -->
                    <router-link to="/profilePoint" class="myorder">
                        <aside>
                            <van-icon name="sign" size="22" color="#231F20" />
                        </aside>
                        <div class="myorder-div">
                            <span>我的打卡</span>
                            <span class="myorder-divsvg">
                                <svg fill="#999">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="#arrow-right"
                                    ></use>
                                </svg>
                            </span>
                        </div>
                    </router-link>
                    <!-- <router-link to="/download" class="myorder">
                        <aside>
                            <van-icon name="info-o" size="22" color="#231F20" />
                        </aside>
                        <div class="myorder-div" style="border-bottom: 0">
                            <span>关于我们</span>
                            <span class="myorder-divsvg">
                                <svg fill="#999">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="#arrow-right"
                                    ></use>
                                </svg>
                            </span>
                        </div>
                    </router-link> -->
                </section>
            </div>
        </section>
        <transition name="router-slid" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import { getUserInfo } from "@/service";
import headTop from "@/components/header/head";
import footGuide from "@/components/footer/footGuide";
import { mapState, mapMutations, mapActions } from "vuex";
import { imgBaseUrl } from "@/config/env";
import { getImgPath } from "@/components/common/mixin";

export default {
    name: "profilePage",
    data() {
        return {
            profiletitle: "我的",
            username: "登录/注册", //用户名
            resetname: "",
            mobile: "暂无绑定手机号", //电话号码
            balance: 0, //我的余额
            count: 0, //优惠券个数
            pointNumber: 0, //积分数
            avatar: "", //头像地址
            imgBaseUrl,
        };
    },
    async mounted() {
        await this.getUserInfo();
        this.initData();
    },
    mixins: [getImgPath],
    components: {
        headTop,
        footGuide,
    },

    computed: {
        ...mapState(["userInfo"]),
        //后台会返回两种头像地址格式，分别处理
        imgpath: function () {
            let path;
            if (this.avatar.indexOf("/") !== -1) {
                path = imgBaseUrl + this.avatar;
            } else {
                path = this.getImgPath(this.avatar);
            }
            this.SAVE_AVANDER(path);
            return path;
        },
    },

    methods: {
        ...mapActions(["getUserInfo"]),
        ...mapMutations(["SAVE_AVANDER"]),
        initData() {
            if (this.userInfo && this.userInfo.openId) {
                console.log(this.userInfo, "userInfo");
                this.avatar = this.userInfo.avatar;
                this.username = this.userInfo.username;
                this.mobile = this.userInfo.phone || "暂无绑定手机号";
                this.balance = this.userInfo.balance;
                this.count = this.userInfo.gift_amount;
                this.pointNumber = this.userInfo.point;
            } else {
                this.username = "登录/注册";
                this.mobile = "暂无绑定手机号";
            }
        },
    },
    watch: {
        userInfo: function (value) {
            this.initData();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_varibles";
@import "@/style/mixin";

.profile-content {
    padding: 0 36px 0 38px;
}
.profile-avatar {
    @include wh(100%, 400px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $--color-text-primary;
    z-index: 0;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    .profile-title {
        @include sc(32px, #fff);
        margin-top: 28px;
    }
    .profile-link {
        .privateImage {
            display: inline-block;
            @include wh(132px, 132px);
            border-radius: 50%;
            vertical-align: middle;
            .privateImage-svg {
                background: $fc;
                border-radius: 50%;
                @include wh(132px, 132px);
                border: 3px solid #fff;
            }
        }
        .arrow {
            @include wh(0.46667rem, 0.98rem);
            display: inline-block;
            svg {
                @include wh(100%, 100%);
            }
        }
    }
}
.info-data {
    width: 100%;
    height: 192px;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
    margin-top: -60px;
    position: relative;
    z-index: 100;
    background: #fff;
    ul {
        display: flex;
        height: 100%;
        .info-data-link {
            flex: 1;
            display: inline-block;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;
            span {
                display: block;
                width: 100%;
                text-align: center;
            }
            .info-data-bottom {
                @include sc(28px, $--color-text-regular);
                font-weight: 400;
                margin-top: 5px;
            }
        }
    }
}
.profile-1reTe {
    margin-top: 0.4rem;
    background: $fc;
    .myorder {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f1f1f1;
        aside {
            @include wh(40px, 40px);
            display: flex;
            align-items: center;
            margin-right: 30px;
            svg {
                @include wh(100%, 100%);
            }
        }
        .myorder-div {
            width: 100%;
            height: 120px;
            @include sc(28px, $--color-text-regular);
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                display: block;
            }
            .myorder-divsvg {
                @include wh(30px, 30px);
                svg {
                    @include wh(100%, 100%);
                }
            }
        }
    }
}
.router-slid-enter-active,
.router-slid-leave-active {
    transition: all 0.4s;
}
.router-slid-enter,
.router-slid-leave-active {
    transform: translate3d(2rem, 0, 0);
    opacity: 0;
}
</style>
